import { FC, createContext, useContext, useEffect, useState } from "react";
import { WithChildren } from "../../_metronic/helpers";
import useAuth from "../modules/auth/hooks/useAuth";
import { APP_ROUTES, staticRoutes } from "./routes";
import { IModules } from "../pages/moduleCreator/models/Modules.interfaces";
import { setPermissions } from "../modules/auth/features/auth.slice";
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { removeSession } from "../utils/session";
import { TOKEN } from "../modules/auth/AuthHelper";
import { useLazyGetRolesAndPermissionsQuery } from "../app.api";
import { useSelector } from "react-redux";
import { reloadPage, selectReloadPage } from "../app.slice";

interface IDynamicRouteProps {
  dynamicRoutes: IModules[];
  homeRoute: string;
  isLoading: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  error?: any;
}
const DynamicRouteContext = createContext<IDynamicRouteProps>({
  dynamicRoutes: [],
  homeRoute: "",
  isLoading: true,
});

const DynamicRouteProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser, isAuthenticated, currentMode, allModePermissions } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const dispatch: Dispatch = useDispatch();
  const [routes, setRoutes] = useState<IModules[]>([]);
  const [homeRoute, setHomeRoutes] = useState<string>(APP_ROUTES.DASHBOARD);
  const reload = useSelector(selectReloadPage);
  // const [getSidebarCounter, { data: sidebarCounter, status }] = useLazyGetSideBarCounterQuery();
  const [getPermissions, { isSuccess, isError, error }] = useLazyGetRolesAndPermissionsQuery();
  useEffect(() => {
    // for production
    if (currentUser?.user_id) {
      if (currentMode) {
        setLoading(true);

        // if (allModePermissions?.[currentMode as keyof typeof allModePermissions]?.length) {
        //   setRoutes(allModePermissions?.[currentMode as keyof typeof allModePermissions]);
        //   const home =
        //     currentMode === "conduct" ? currentUser?.role?.home : currentUser?.role?.participate_home;
        //   setHomeRoutes(
        //     home ||
        //       allModePermissions?.[currentMode as keyof typeof allModePermissions]?.[0]?.path ||
        //       APP_ROUTES.NO_PERMISSION
        //   );
        //   setLoading(false);
        //   return;
        // } else {
        getPermissions({
          reload,
        })
          .then(({ data }) => {
            let r: { conduct: IModules[]; participate: IModules[] } = { conduct: [], participate: [] };
            let h: string = homeRoute;
            const role = currentUser?.role?.role_slug || currentUser?.access;

            if (role && staticRoutes?.[role]) {
              r = { conduct: staticRoutes?.[role], participate: staticRoutes?.[role] };

              if (data?.data?.permissions) {
                r = {
                  conduct: [...r?.conduct, ...data?.data?.permissions?.conduct],
                  participate: [...r?.participate, ...data?.data?.permissions?.participate],
                };
              }

              if (currentMode === "conduct" && data?.data?.home) {
                h = `/${data?.data?.home}`;
              } else if (currentMode === "participate" && data?.data?.participate_home) {
                h = `/${data?.data?.participate_home}`;
              } else h = APP_ROUTES.NO_PERMISSION;
            } else {
              // for only dynamic routes are present
              r = data?.data?.permissions as { conduct: IModules[]; participate: IModules[] };
              if (currentMode === "conduct" && data?.data?.home) {
                h = `/${data?.data?.home}`;
              } else if (currentMode === "participate" && data?.data?.participate_home) {
                h = `/${data?.data?.participate_home}`;
              } else h = `/${data?.data?.home}`;
            }

            const formattedRoute = r;
            setRoutes(formattedRoute?.[currentMode as keyof typeof formattedRoute]);
            dispatch(setPermissions(formattedRoute));
            setHomeRoutes(`${h}`);
          })
          .catch((e) => console.error(e))
          .finally(() => {
            setLoading(false);
            dispatch(reloadPage(""));
          });
        // }
      }
      // getSidebarCounter();
    } else {
      const token = isAuthenticated();
      if (token) {
        removeSession(TOKEN);
        window.location.reload();
      }
    }
    // for production end

    // for mocking

    // if (currentUser?.access) {
    //   try {
    //     const mockPermissions = currentUser?.mode_type === 'participate' ? mockParticipateModeRoutes : mockConductModeRoutes
    //     let r: IModules[] = [];
    //     if (currentUser?.access && staticRoutes?.[currentUser.access])
    //       r = [...staticRoutes?.[currentUser.access]]
    //     r = [...r, ...mockPermissions?.data?.permissions as IModules[]];
    //     setRoutes(r);
    //     dispatch(setPermissions(r))
    //     setHomeRoutes(mockPermissions.data?.home);
    //   } catch (error) {
    //     console.error('error', error);
    //   }
    // }
    // setLoading(false)
    // for mocking end
  }, [currentUser?.user_id, currentMode]);

  // useEffect(() => {
  //   if (status === QueryStatus.fulfilled) {
  //     let s = {};
  //     sidebarCounter?.data?.forEach((v: ISidebarCounter) => {
  //       s = { ...s, [v.path]: v?.counter };
  //     });
  //     dispatch(updateSidebarCounter(s));
  //   }
  // }, [status, sidebarCounter, dispatch]);

  return (
    <DynamicRouteContext.Provider
      value={{ dynamicRoutes: routes, homeRoute, isLoading, isSuccess, isError, error }}
    >
      {children}
    </DynamicRouteContext.Provider>
  );
};

export { DynamicRouteContext, DynamicRouteProvider };

export function useDynamicRoutes() {
  return useContext(DynamicRouteContext);
}
