import SelectModeType from "../../components/SelectModeType/SelectModeType";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setModeType } from "../../modules/auth/features/auth.slice";
import IconButton from "../../components/IconButton/IconButton";
import { ChangeEvent, useEffect } from "react";
import { IModeType } from "../users/models/Users.interfaces";
import useAuth from "../../modules/auth/hooks/useAuth";
interface IAuctionMode {
  mode_type: "participate" | "conduct";
}
const AuctionMode = () => {
  const { $t } = useIntl();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const methods = useForm<IAuctionMode>();
  const onSubmit = (data: IAuctionMode) => {};

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setModeType({ mode: e.target.value as IModeType }));
  };

  useEffect(() => {
    if (currentUser?.role?.mode_type && currentUser?.role?.mode_type !== "conduct,participate")
      dispatch(setModeType({ mode: currentUser?.role?.mode_type as IModeType }));
  }, [currentUser?.role?.mode_type]);
  
  return (
    <div className="d-flex w-100 h-100 flex-center">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="fw-bold d-flex flex-center text-gray-900 mb-10" style={{ fontSize: "3.625rem" }}>
          {$t({ id: "AUCTION_MODE.PAGE.TITLE" })}
        </h2>

        <FormProvider {...methods}>
          <SelectModeType onChange={handleChange} />
        </FormProvider>

        <div className="d-flex flex-center" style={{ marginTop: 150 }}>
          <div className="p-4 bg-primary rounded-circle symbol">
            <IconButton
              iconClassName="text-white"
              iconSize={48}
              icon="arrow-right"
              iconType="solid"
              type="submit"
            ></IconButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AuctionMode;
