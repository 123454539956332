import { createContext, FC, useContext } from "react";
import { WithChildren } from "../../../_metronic/helpers";
import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
export interface FirebaseContextModel {
  firebaseApp: FirebaseApp | null;
  firebaseAnalytics: Analytics | null;
}

const FirebaseContext = createContext<FirebaseContextModel>({
  firebaseApp: null,
  firebaseAnalytics: null,
});

const FirebaseProvider: FC<WithChildren> = ({ children }) => {
  const firebaseConfig = {
    apiKey: import.meta.env.VITE_APP_API_KEY,
    authDomain: import.meta.env.VITE_APP_AUTHD_DOMAIN,
    projectId: import.meta.env.VITE_APP_PROJECT_ID,
    storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDED_ID,
    appId: import.meta.env.VITE_APP_APP_ID,
    measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseAnalytics = getAnalytics(firebaseApp);
  const value: FirebaseContextModel = { firebaseApp, firebaseAnalytics };

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};

export { FirebaseContext, FirebaseProvider };

export function useFirebase() {
  return useContext(FirebaseContext);
}
