import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";
import useAuth from "../../../../app/modules/auth/hooks/useAuth";
import { APP_ROUTES } from "../../../../app/routing/routes";
import { BROADCAST_EVENT, sendTabEvent } from "../../../../app/hooks/useBrowserTabEvent";
import { Button } from "react-bootstrap";
import { IModeType } from "../../../../app/pages/users/models/Users.interfaces";
import { useIntl } from "react-intl";
import SVG from "react-inlinesvg";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectInitialModeType } from "../../../../app/modules/auth/features/auth.slice";
import { useDynamicRoutes } from "../../../../app/routing/DynamicRouteProvider";

const HeaderUserMenu: FC = () => {
  const { $t } = useIntl();
  const { currentUser, currentRole, permissions, currentMode, changeModeType } = useAuth();
  const initial_mode_type = useSelector(selectInitialModeType);
  const isFirstRender = useRef(true);

  const { isLoading } = useDynamicRoutes();
  const navigate = useNavigate();
  const handleLogout = () => {
    sendTabEvent(BROADCAST_EVENT.LOGOUT_EVENT);
  };
  const handleChangeMode = (mode: IModeType) => (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    changeModeType({ mode });
  };
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }
    const targetRoute =
      currentMode === "conduct" ? currentUser?.role?.home : currentUser?.role?.participate_home;
    if (targetRoute && !isLoading) {
      navigate(targetRoute);
    }
  }, [isLoading, currentMode]);
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px"
      data-kt-menu="true"
      data-popper-placement="bottom-start"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={currentUser?.profile_image || toAbsoluteUrl("media/avatars/blank.png")} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.user_name || currentUser?.user_id}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {currentRole}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      {initial_mode_type === "conduct,participate" ? (
        <div className="d-flex p-5 br-4 btn-group">
          {["conduct", "participate"].map((mode, index) => {
            const isSelected = currentMode === mode;
            return (
              <Button
                key={mode}
                variant={currentMode === mode ? "primary" : "outline"}
                style={{
                  borderBottomLeftRadius: index === 0 ? 40 : 0,
                  borderTopLeftRadius: index === 0 ? 40 : 0,
                  borderBottomRightRadius: index === 1 ? 40 : 0,
                  borderTopRightRadius: index === 1 ? 40 : 0,
                }}
                onClick={handleChangeMode(mode as IModeType)}
              >
                <div className="d-flex gap-2 align-items-center">
                  <SVG
                    src={toAbsoluteUrl(`media/misc/${mode}-mode.svg`)}
                    width={20}
                    height={20}
                    title={mode as string}
                    className={clsx(!isSelected && "d-block", isSelected && "d-none")}
                  />
                  <SVG
                    src={toAbsoluteUrl(`media/misc/${mode}-mode-dark.svg`)}
                    width={20}
                    height={24}
                    title={mode as string}
                    className={clsx(isSelected && "d-block", !isSelected && "d-none")}
                  />
                  {mode === "conduct"
                    ? $t({ id: "MENU.HEADER.CONDUCT_MODE" })
                    : $t({ id: "MENU.HEADER.PARTICIPATE_MODE" })}
                </div>
              </Button>
            );
          })}
        </div>
      ) : null}
      {permissions?.["MyProfile"] && (
        <div className="menu-item px-5">
          <Link
            to={currentMode === "conduct" ? APP_ROUTES.MY_PROFILE : APP_ROUTES.PROFILE}
            className="menu-link px-5"
          >
            {$t({ id: "MENU.HEADER.MY_PROFILE" })}
          </Link>
        </div>
      )}
      {permissions?.["Users"] && (
        <div className="menu-item px-5">
          <Link to={APP_ROUTES.USERS} className="menu-link px-5">
            {$t({ id: "MENU.HEADER.MANAGE_USER" })}
          </Link>
        </div>
      )}
      {permissions?.["Settings"] && (
        <div className="menu-item px-5 my-1">
          <Link to={APP_ROUTES.SETTINGS} className="menu-link px-5">
            {$t({ id: "MENU.HEADER.SETTINGS" })}
          </Link>
        </div>
      )}
      <div className="menu-item px-5 my-1">
        <Link to={APP_ROUTES.HELP} className="menu-link px-5">
          {$t({ id: "MENU.HEADER.HELP" })}
        </Link>
      </div>
      <div className="menu-item px-5">
        <button onClick={handleLogout} className="fw-bold btn btn-flat w-100 menu-link px-5">
          {$t({ id: "MENU.HEADER.SIGN_OUT" })}
        </button>
        {/* <a onClick={logout} className='menu-link px-5'>
          
        </a> */}
      </div>
    </div>
  );
};

export { HeaderUserMenu };
