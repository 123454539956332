import { useEffect, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import { useLazyGetPreviewDocumentQuery } from "../services/previewDocument.api";
import SplashScreen from "../../../components/SplashScreen/SplashScreen";
import { Card } from "react-bootstrap";
import { downloadFile } from "../../../utils/helpers";
import { useLocation } from "react-router-dom";

const PreviewDocument = () => {
  const errortHtml = `<div class="d-flex flex-column flex-center h-100">
            <i class="fas fa-exclamation-circle text-warning mb-3" style="font-size:90px;"></i>
            <h3>Sorry! Unable to fetch data</h3>
        </div>`;
  const params = useLocation();
  const [loading, setLoading] = useState(true);
  const [getDocument] = useLazyGetPreviewDocumentQuery();
  const [htmlData, setHtmlData] = useState("<h3>Loading...</h3>");
  useEffect(() => {
    getDocument(params.search)
      .then(({ data }) => {
        if (data?.status) {
          if (data?.data?.attachment_type === "attachment") {
            downloadFile(data?.data?.attachment_url, data?.data?.attachment_name);

            window.close();
          } else {

            setHtmlData(data?.data?.termsAndConditions);
          }
        }else{
        setHtmlData(errortHtml);

        }
      })
      .catch(() => {
        setHtmlData(errortHtml);

      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (loading) return <SplashScreen />;
  return (
    <div className="container w-100 h-100">
      <div className="d-flex flex-center h-100 w-100">
        <Card>
          <Card.Body>
            <div dangerouslySetInnerHTML={{ __html: htmlData }}></div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default PreviewDocument;
