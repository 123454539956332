import { useFormContext } from "react-hook-form";
import FieldErrorText from "../FormBuilder/FieldErrorText";
import { useIntl } from "react-intl";
import SelectType, { ISelectType, ISelectTypeItem } from "../SelectType/SelectType";
import { ChangeEvent, ChangeEventHandler } from "react";

const SelectModeType = (props: Partial<ISelectType>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { $t } = useIntl();
  const modeTypes: ISelectTypeItem[] = [
    {
      id: "conduct_mode",
      title: $t({ id: "AUCTION_MODE.CONDUCT.TITLE" }),
      description: $t({ id: "AUCTION_MODE.CONDUCT.DESCRIPTION" }),
      value: "conduct",
      iconPath: "media/misc/conduct-mode.svg",
      iconDarkPath: "media/misc/conduct-mode-dark.svg",
    },
    {
      id: "participate",
      title: $t({ id: "AUCTION_MODE.PARTICIPATE.TITLE" }),
      description: $t({ id: "AUCTION_MODE.PARTICIPATE.DESCRIPTION" }),
      value: "participate",
      iconPath: "media/misc/participate-mode.svg",
      iconDarkPath: "media/misc/participate-mode-dark.svg",
    },
  ];
  const { onChange, ...rest } = register("mode_type", {
    required: { value: true, message: "Mode type is required" },
  });

  const handleChange =
    (handler?: ChangeEventHandler<HTMLInputElement>) => (e: ChangeEvent<HTMLInputElement>) => {
      if (handler) handler(e);
      onChange(e);
    };

  return (
    <SelectType
      items={modeTypes}
      error={(errors?.mode_type?.message as string) || ""}
      {...rest}
      {...props}
      onChange={props?.onChange ? handleChange(props?.onChange) : onChange}
    />
  );
};

export default SelectModeType;
