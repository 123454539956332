import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  PersistConfig,
} from "redux-persist";
import rootReducer from "./rootReducer";
import { api } from "../app/services/api/api";
import { setupListeners } from "@reduxjs/toolkit/query";
import createTransform from "redux-persist/es/createTransform";

// Transform to omit specific nested keys
// const omitNestedKey = createTransform(
//   // Transform state on its way to being serialized and persisted
//   (inboundState, key) => {
//     if (key === "auth") {
//       const { permissions, ...rest } = inboundState as any;
//       return rest; // Persist everything except `permissions`
//     }
//     return inboundState;
//   },
//   // Transform state on its way to being rehydrated
//   (outboundState, key) => outboundState, // No changes on rehydration
//   { whitelist: ["auth"] } // Apply only to `auth`
// );
const persistConfig: any = {
  key: "root",
  storage,
  blacklist: [api.reducerPath, "app"],
  // transforms: [omitNestedKey],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

const persistor = persistStore(store);
setupListeners(store.dispatch);
export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
