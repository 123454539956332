import { appNotification } from "../../../app.slice";
import { IResponse } from "../../../network/network.interfaces";
import { api } from "../../../services/api/api";
import { ProductBaseApi } from "../../../services/api/api.constant";

export const PREVIEW_DOCUMENT_API = ProductBaseApi;

export const productApi = api.enhanceEndpoints({ addTagTypes: ["PreviewDocument"] }).injectEndpoints({
  endpoints: (builder) => ({
    getPreviewDocument: builder.query<IResponse<any>, any>({
      query: (queryString?: any) => {
        return `${PREVIEW_DOCUMENT_API}/preview${queryString}`;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(appNotification({ title: "Error", msg: "Error while getting data!" }));
        }
      },
    }),
  }),
});

export const { useGetPreviewDocumentQuery, useLazyGetPreviewDocumentQuery } = productApi;
