import { FC, ReactNode, Suspense } from "react";
import { Route } from "react-router-dom";
import { ROLES } from "../utils/constants";
import { WithChildren } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { routeComponentMap } from "./routeComponents";
import { IModuleAction, Modules } from "../pages/Modules/models/Modules.interfaces";
import { IModules } from "../pages/Modules/models/Modules.interfaces";
import { createDefaultActionValue } from "../pages/Modules/utils/helpers";
import { DEFAULT_ACTION } from "../pages/Modules/utils/constants";

interface IRoute {
  type?: string;
  path?: string;
  element?: string;
  role?: string[];
  title?: string;
  breadCrumbs?: PageLink[];
  sidebarOptions?: {
    icon?: string;
    hidden?: boolean;
  };
  index?: boolean;
  children?: IRoute[];
}
// const r = [
//     {
//         path: "/",
//         element: '<App' />,
//         children: [

//             {
//                 path: "dashboard",
//                 element: '<DashboardWrapper' />
//                 // loader: ({ request }) =>
//                 //         fetch("/api/dashboard.json", {
//                 //             signal: request.signal,
//                 //         }),
//             },
//         ],
//     }, {
//         element: '<AuthLayout' />,
//             children: [
//             {
//                 path: "login",
//                 element: '<Login' />,
//                 loader: redirectIfUser,
//             },
//             {
//                 path: "logout",
//                 action: logoutUser,
//             },
//         ],
//     }
// ]

export const PUBLIC_ROUTES = {
  ERROR_404: "error/404",
  ERROR_500: "error/500",
  ERROR_401: "error/401",
  LOADING_SCREEN: "/loadingPage",
  PREVIEW_DOCUMENT: "/preview-document",
};

export const AUTH_ROUTES = {
  LOGIN: "/login",
  REGISTRATION: "/registration",
  FORGOT_PASSWORD: "/forgot-password",
};

export const APP_ROUTES = {
  DEFAULT_PAGE: "/default",
  TENDER_NEW: "/tender/new",
  TENDER_DRAFT: "/tender/draft",
  TENDER_APPROVE: "/tender/approve",
  REVERSE_AUCTION_NEW: "/reverse-auction/new",
  REVERSE_AUCTION_DRAFT: "/reverse-auction/draft",
  REVERSE_AUCTION_APPROVE: "/reverse-auction/approve",
  CONDUCT_LIVE_REVERSE_AUCTION: "/reverse-auction/live",
  REVERSE_AUCTION_TAB: "/reverse-auction/tab",
  REVERSE_AUCTION_TENDER_LIST: "/reverse-auction/tender-list",
  FORWARD_AUCTION_NEW: "/forward-auction/new",
  FORWARD_AUCTION_DRAFT: "/forward-auction/draft",
  FORWARD_AUCTION_APPROVE: "/forward-auction/approve",
  CONDUCT_LIVE_FORWARD_AUCTION: "/forward-auction/live",
  EQUIPMENT_LISTING_NEW: "/forward-auction/new",
  EQUIPMENT_LISTING_DRAFT: "/forward-auction/draft",
  EQUIPMENT_LISTING_PUBLISH: "/forward-auction/publish",
  UPCOMING_TENDER: "/upcoming-events/upcoming-tender",
  UPCOMING_REVERSE_AUCTION: "/upcoming-events/reverse-auction",
  UPCOMNIG_FORWARD_AUCTION: "/upcoming-event/foward-auction",
  FORWARD_AUCTION_TAB: "/forward-auction/tab",
  FORWARD_AUCTION_TENDER_LIST: "/forward-auction/tender-list",
  PENDING_APPROVAL: "/pending-approval",
  AUCTION_MODE: "/auction-mode",
  DASHBOARD: "/dashboard",
  LAYOUT_BUILDER: "/builder",
  MODULE_CREATOR: "/addModule",
  LIST_MODULE: "/listModule",
  MODULES: "/modules",
  USERS: "/masters/users",
  ROLES: "/roles",
  LOGOUT: "/logout",
  PROFILE: "/profile",
  MY_PROFILE: "/my-profile",
  SETTINGS: "/settings",
  HELP: "/help",
  PRODUCT_PACKAGE_SORTING: "/products/product-package-sorting",
  REPORTS: "/reports",
  TRACK_DELIVERY: "/track-delivery",
  UPLOADER: "/uploader",
  BILLING_REVIEW: "/billing-review",
  CITY: "/locations/cities",
  AREA: "/locations/area",
  SUB_AREA: "/locations/sub-area",
  DELIVERY_USER: "/locations/delivery-user",
  HUB_DISTRIBUTOR: "/hubs-distrubutors",
  CUSTOMER: "/customers",
  VIEW_CUSTOMER: "/customers/view-customer",
  NO_PERMISSION: "/no-permission",
  // settings
  WALLET_REASON: "/settings/wallet-reason",
  ACTIVITY_LOG: "/activity-logs",
  // reports
  OrderSortingReport: "reports/order-sorting-report",
  ProductSalesCustomerWise: "reports/product-sales-customer-wise",
  OrderReport: "reports/Order-report",
  CummulativeSalesReport: "reports/cumulative-sales-report",
  ActivityLogsReport: "reports/activity-logs-report",
  BackOrdersReport: "reports/auto-pause-low-wallet",
  VacationsReport: "reports/pause-report",
  CancelOrderReport: "reports/cancel-order-report",
  CashCollectionReport: "reports/cash-collection-report",
  CustomerLifeCycleReport: "reports/customer-lifecycle-report",
  CustomerStatementReport: "reports/customer-statement-report",
  CustomerSalesReport: "reports/customer-sales-report",
  CustomerVacationsReport: "reports/customer-pause-report",
  CustomerWalletReport: "reports/customer-wallet-report",
  CustomerReferralReport: "reports/customer-referral-report",
  DeliveryExecutiveReferralReport: "reports/delivery-executive-referral-report",
  FutureOrdersReport: "reports/future-orders-report",
  HubDeliveryReport: "reports/hub-delivery-report",
  LowCreditsReport: "reports/low-credits-report",
  NewLocationRequestReport: "reports/new-location-request-report",
  OrderSheetReport: "reports/order-sheet-report",
  PredictiveAnalysisReport: "reports/predictive-analysis-report",
  ReverseLogisticsReport: "reports/reverse-logistics-report",
  ReturnsReport: "reports/returns-report",
  RLSummaryReport: "reports/rl-summary-report",
  SubscriptionReport: "reports/subscription-report",
  TransactionsReport: "reports/transactions-report",
  SampleReport: "reports/samples",
  TentativeDemandSheet: "reports/tentative-demand-sheet",
  GenerateInvoice: "reports/generate-invoice-report",
  OtpRequest: "otp-request",
  ComboPackages: "products/comboPackages",
  AllTransactionReport: "reports/all-transactions-report",
  PendingOrderDeliveryBoyReport: "reports/pending-order-delivery-boy-report",
  DeliveryBoyRoutes: "reports/delivery-boy-routes",
  FarmVisitRequestReport: "reports/farm-visit-request-report",
  DeliveryHeatMapReport: "reports/delivery-heat-map-report",
  CustomerCallLogsReport: "reports/customer-call-logs-report",
  CustomerAddressChangeReport: "reports/customer-address-change-report",
  ForceChangePassword: "force-change-password",
  COMMUNICATION: "communications",
  DISTRIBUTOR_DASHBOARD: "/distributor-dashboard",
  DISTRIBUTOR_PRODUCTS: "/distributor-products",
  DISTRIBUTOR_PACKAGES: "/distributor-packages",
  DISTRIBUTOR_REPORTS: "/distributor-reports",
  TERMS_AND_CONDITION: "/terms-and-condition",
  PRODUCT: "/product",
  //app-routes
};

export const publicRoutes: IRoute[] = [
  {
    path: PUBLIC_ROUTES.ERROR_404,
    element: "Error404",
    sidebarOptions: {
      hidden: true,
    },
  },
  {
    path: PUBLIC_ROUTES.ERROR_500,
    element: "Error500",
    sidebarOptions: {
      hidden: true,
    },
  },
  {
    path: PUBLIC_ROUTES.ERROR_401,
    element: "Error401",
    sidebarOptions: {
      hidden: true,
    },
  },
  {
    path: PUBLIC_ROUTES.ERROR_401,
    element: "Error401",
    sidebarOptions: {
      hidden: true,
    },
  },
  {
    path: PUBLIC_ROUTES.LOADING_SCREEN,
    element: "LoadingPage",
    sidebarOptions: {
      hidden: true,
    },
  },
  {
    type: "item",
    path: PUBLIC_ROUTES.PREVIEW_DOCUMENT,
    element: "PreviewDocument",
    title: "Preview Document",
    sidebarOptions: {
      icon: "document",
      hidden: true,
    },
  },
];

export const authRoutes: IRoute[] = [
  {
    path: AUTH_ROUTES.LOGIN,
    element: "Login",
    title: "Login",
  },

  {
    path: AUTH_ROUTES.FORGOT_PASSWORD,
    element: "ForgotPassword",
    title: "Forgot Password",
  },
  {
    element: "Login",
    index: true,
  },
];

export const commonRoutes: IModules[] = [
  {
    id: -8,
    type: "item",
    path: APP_ROUTES.PROFILE,
    element: "MyProfile",
    title: "Profile",
    parentId: -1,
    sidebarOptions: {
      icon: "switch",
      hidden: true,
    },
  },
  {
    id: -9,
    type: "item",
    path: APP_ROUTES.HELP,
    element: "Help",
    title: "Help",
    parentId: -1,
    sidebarOptions: {
      icon: "question-2",
      hidden: true,
    },
  },
];

export const staticRoutes: { [x: string]: IModules[] } = {
  [ROLES.SUPER_ADMIN]: [
    // {
    //   id: -3,
    //   parentId: undefined,
    //   type: "item",
    //   path: APP_ROUTES.MODULES,
    //   element: "Modules",
    //   title: "NAVIGATION.CONDUCT.MODULES",
    //   sidebarOptions: {
    //     icon: "switch",
    //   },
    //   actions: createDefaultActionValue(
    //     {
    //       [DEFAULT_ACTION.CREATE]: true,
    //       [DEFAULT_ACTION.DELETE]: false,
    //       [DEFAULT_ACTION.READ]: false,
    //       [DEFAULT_ACTION.UPDATE]: true,
    //     },
    //     [
    //       {
    //         id: -1,
    //         key: "add_action",
    //         title: "Add new permission",
    //         type: "inline",
    //         value: true,
    //       },
    //     ]
    //   ),
    // },
    // {
    //     "id": -4,
    //     parentId: -1,
    //     type: 'item',
    //     "path": APP_ROUTES.LAYOUT_BUILDER,
    //     "element": "BuilderPageWrapper",
    //     "title": "Layout builder",
    //     "sidebarOptions": {
    //         "icon": "switch"
    //     }
    // },
  ],
  [ROLES.ADMIN]: [
    {
      id: -3,
      parentId: undefined,
      type: "item",
      path: APP_ROUTES.MODULES,
      element: "Modules",
      title: "Modules",
      sidebarOptions: {
        icon: "switch",
      },
      actions: createDefaultActionValue(
        {
          [DEFAULT_ACTION.CREATE]: true,
          [DEFAULT_ACTION.DELETE]: false,
          [DEFAULT_ACTION.READ]: false,
          [DEFAULT_ACTION.UPDATE]: true,
        },
        [
          {
            id: -1,
            key: "add_action",
            title: "Add new permission",
            type: "inline",
            value: true,
          },
        ]
      ),
    },
    // {
    //     "id": -6,
    //     type: 'item',
    //     parentId: -1,
    //     assignable: true,
    //     "path": APP_ROUTES.USERS,
    //     "element": "Users",
    //     "title": "Users",
    //     "sidebarOptions": {
    //         "icon": "switch",
    //         "hidden": true
    //     },
    //     "actions": createDefaultActionValue({
    //         [DEFAULT_ACTION.CREATE]: true,
    //         [DEFAULT_ACTION.UPDATE]: true,
    //         [DEFAULT_ACTION.READ]: true,
    //         [DEFAULT_ACTION.DELETE]: true
    //     }, [
    //         {
    //             type: 'global',
    //             key: 'manage-roles',
    //             title: 'Manage Roles',
    //             value: true,
    //         },
    //         {
    //             type: 'inline',
    //             key: 'change_password',
    //             title: 'Change password',
    //             value: true,
    //             icon: {
    //                 icon: 'lock',
    //                 iconType: 'duotone',
    //                 iconStyle: { color: 'GrayText' }
    //             }
    //         },
    //         {
    //             type: 'inline',
    //             key: 'hub_mapping',
    //             title: 'Hub mapping',
    //             value: true,
    //             icon: {
    //                 icon: 'people',
    //                 iconType: 'duotone',
    //                 iconStyle: { color: 'purple' }
    //             }
    //         }
    //     ])
    // },
    // {
    //     "id": -7,
    //     type: 'item',
    //     parentId: null,
    //     "path": APP_ROUTES.ROLES,
    //     "element": "Roles",
    //     "title": "Roles",
    //     "sidebarOptions": {
    //         "icon": "switch",
    //         "hidden": true
    //     },
    //     "actions": createDefaultActionValue({
    //         [DEFAULT_ACTION.CREATE]: true,
    //         [DEFAULT_ACTION.UPDATE]: true,
    //         [DEFAULT_ACTION.READ]: false,
    //         [DEFAULT_ACTION.DELETE]: true
    //     })
    // },
    // {
    //     "id": -8,
    //     type: 'item',
    //     "path": APP_ROUTES.PROFILE,
    //     "element": "Profile",
    //     "title": "Profile",
    //     parentId: -1,
    //     "sidebarOptions": {
    //         "icon": "switch",
    //         "hidden": true
    //     },
    // },
    // {
    //     "id": -9,
    //     type: 'item',
    //     "path": APP_ROUTES.HELP,
    //     "element": "Help",
    //     "title": "Help",
    //     parentId: -1,
    //     "sidebarOptions": {
    //         "icon": "question-2",
    //         "hidden": true
    //     },
    // },
    // {
    //     "id": -10,
    //     type: 'item',
    //     "path": APP_ROUTES.CITY,
    //     "element": "Cities",
    //     "title": "Cities",
    //     parentId: 5,
    //     "sidebarOptions": {
    //         "icon": "map",
    //     },
    //     "actions": createDefaultActionValue({
    //         [DEFAULT_ACTION.CREATE]: true,
    //         [DEFAULT_ACTION.UPDATE]: true,
    //         [DEFAULT_ACTION.READ]: false,
    //         [DEFAULT_ACTION.DELETE]: true
    //     })
    // },
    // {
    //     "id": -11,
    //     type: 'item',
    //     "path": APP_ROUTES.AREA,
    //     "element": "Area",
    //     "title": "Area",
    //     parentId: 5,
    //     "sidebarOptions": {
    //         "icon": "map",
    //     },
    //     "actions": createDefaultActionValue({
    //         [DEFAULT_ACTION.CREATE]: true,
    //         [DEFAULT_ACTION.UPDATE]: true,
    //         [DEFAULT_ACTION.READ]: false,
    //         [DEFAULT_ACTION.DELETE]: true
    //     })
    // },
    // {
    //     "id": -12,
    //     type: 'item',
    //     "path": APP_ROUTES.SUB_AREA,
    //     "element": "SubArea",
    //     "title": "Sub Area",
    //     parentId: 5,
    //     "sidebarOptions": {
    //         "icon": "map",
    //     },
    //     "actions": createDefaultActionValue({
    //         [DEFAULT_ACTION.CREATE]: true,
    //         [DEFAULT_ACTION.UPDATE]: true,
    //         [DEFAULT_ACTION.READ]: false,
    //         [DEFAULT_ACTION.DELETE]: true
    //     })
    // },
  ],
};
export const createRoutes = ({
  element,
  path,
  title,
  breadCrumbs,
  actions,
  routeKey,
}: {
  routeKey: string;
  element?: string;
  path?: string;
  title: string;
  breadCrumbs: any;
  actions?: IModuleAction[];
}) => {
  let Component: any;
  if (element) Component = routeComponentMap?.[element];
  if (!Component) return;

  return (
    <Route
      key={routeKey}
      path={path}
      element={
        <SuspensedView>
          {title && <PageTitle breadcrumbs={breadCrumbs || []}>{title}</PageTitle>}
          <Component actions={actions} />
        </SuspensedView>
      }
    />
  );
};

export const getRoutesNew = (routes: Modules[], currentRole: string) => {
  const all = routes
    .map(({ element, path, title, children, actions, ...route }: Modules, i: number) => {
      const breadCrumbs: PageLink[] = [];

      breadCrumbs.push({
        title,
        path: path || "",
        isActive: !children,
      });
      if (children) {
        const updatedChildren = getRoutesNew(children, currentRole);
        return (
          <Route key={`${path || title}-${i}`} index={route.index}>
            {...updatedChildren}
          </Route>
        );
      }
      if (actions) {
        const actionWithRoute = actions.filter((a) => !!a.element);

        if (actionWithRoute.length > 0) {
          const parentActionRoute = createRoutes({
            routeKey: `${path || title}-${i}`,
            element: element,
            breadCrumbs,
            actions,
            path: path,
            title: title,
          });

          const actionRoutes = actionWithRoute
            .map((action, j) => {
              const actionBreadCrumbs: PageLink[] = [
                {
                  isActive: false,
                  title: title,
                  path: path || "",
                  isSeparator: false,
                },
                {
                  isActive: true,
                  title: action.title,
                  path: action.path || "",
                  isSeparator: true,
                },
              ];

              return createRoutes({
                routeKey: `${action.path || action.title}-${j}`,
                element: action.element,
                breadCrumbs: actionBreadCrumbs,
                actions,
                path: action.path,
                title: action.title,
              });
            })
            .filter(Boolean);

          return (
            <Route key={`${path || title}-${i}`}>
              {parentActionRoute}
              {...actionRoutes}
            </Route>
          );
        }
      }
      return createRoutes({ routeKey: `${path || title}-${i}`, element, breadCrumbs, actions, path, title });
    })
    .filter(Boolean);
  return all;
};
export const getRoutes = (routes: IRoute[], currentRole?: string) => {
  let allRoute = routes;
  allRoute = allRoute.filter(
    (route: any) => !route?.type && (route.role ? route.role.includes(currentRole) : !route?.type)
  );
  const all = allRoute
    .map(({ role, element, sidebarOptions, breadCrumbs, title, children, ...route }: IRoute, i: number) => {
      let Component: any;
      if (element) Component = routeComponentMap?.[element];

      if (children) {
        const updatedChildren = getRoutes(children, currentRole);
        return (
          <Route key={`${route.path || title}-${i}`} index={route.index} element={<Component />}>
            {...updatedChildren}
          </Route>
        );
      }
      return (
        <Route
          key={`${route.path || title}-${i}`}
          {...route}
          element={
            <SuspensedView>
              {title && <PageTitle breadcrumbs={breadCrumbs || []}>{title}</PageTitle>}
              <Component />
            </SuspensedView>
          }
        />
      );
    })
    .filter(Boolean);

  return all;
};

export const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 5,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
