import { createSelector, createSlice } from "@reduxjs/toolkit";
import { authApi } from "../services/auth.api";
import { RootState } from "../../../../store/store";
import { removeAuth, saveAuth } from "../AuthHelper";
import { IModeType, User } from "../../../pages/users/models/Users.interfaces";
import { IModuleAction, IModules } from "../../../pages/moduleCreator/models/Modules.interfaces";

export const mockLoginData = {
  status: true,
  message: "Login successful",
  data: {
    user_name: "akash",
    user_id: "admin_akash",
    user_email: "admin@vachanfoods.in",
    access: "v",
    role: {
      id: 1,
      role: "admin",
      role_slug: "ADMIN",
      role_type: 1,
      home: "dashboard",
    },
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3MiOiJBaGw4WXdMeEFlemVNZDQrUEVNRTJtdFNaRmZPSUxRUXMwV3IwS3RSM2pJVkZiK2g0REVxMjUyRWxhSVFlVVUyIiwiZXhwIjoxNzEyODIzMjcxLCJpc3MiOiJhbGxzb2Z0LmNvIiwiYXVkIjoiYWxsc29mdC5jbyJ9.g9DGO6f0yP7c4HxQNZqCo6gMKCwlZN6YNzXo40eyRbI",
  },
};

export type TPermissions = { conduct: IModules[]; participate: IModules[] } | null;
export type AuthState = {
  user: User | null;
  token: string | null;
  permissions: TPermissions;
  deAuth?: string | null;
};

const slice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, deAuth: null } as AuthState,
  reducers: {
    logoutUser: (state: AuthState) => {
      state.user = null;
      state.token = null;
      state.permissions = null;
      removeAuth();
    },
    mockLogin: (state: AuthState) => {
      const { token, ...user } = mockLoginData.data;
      state.token = token as string;
      state.user = user;
      saveAuth(token as string);
    },
    mockLoginWithPhone: (state: AuthState) => {
      const { token, ...user } = mockLoginData.data;
      state.token = token as string;
      state.user = user;
      saveAuth(token as string);
    },
    setPermissions: (state: AuthState, { payload }) => {
      state.permissions = payload;
    },
    setModeType: (state: AuthState, { payload }: { payload: { mode: IModeType } }) => {
      state.user = {
        ...state.user,
        mode_type: payload.mode,
        user_id: state.user?.user_id || "",
        access: state.user?.access || "",
        role: state.user?.role!,
      };
    },
    updateForceChangePassword: (
      state: AuthState,
      { payload }: { payload: { force_password_change: number } }
    ) => {
      state.user = { ...state.user, ...payload } as User;
    },
    deAuthUser: (state: AuthState, { payload }: { payload: string | null }) => {
      state.deAuth = payload;
    },
    refreshLogin: (state: AuthState, { payload }: { payload: User }) => {
      const { token, ...user } = payload;
      state.token = token as string;
      state.user = { ...user };
      saveAuth(token as string);
    },
    updateProfileImage: (state: AuthState, { payload }: { payload: { profile_image: string } }) => {
      state.user = { ...state.user, ...payload } as User;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload.status && payload.data) {
        const { token, ...user } = payload.data;
        state.token = token as string;
        state.user = {
          ...user,
          initial_mode_type: user?.role?.mode_type,
        };
        saveAuth(token as string);
      }
    }),
      builder.addMatcher(authApi.endpoints.validateOtp.matchFulfilled, (state, { payload }) => {
        if (payload.status && payload.data) {
          const { token, ...user } = payload.data;
          state.token = token as string;
          state.user = { ...user, initial_mode_type: user?.role?.mode_type };
          saveAuth(token as string);
        }
      }),
      builder.addMatcher(authApi.endpoints.getProfile.matchFulfilled, (state, { payload }) => {
        if (payload.status) {
          const user = payload.data;
          state.user = user;
        }
      });
  },
});

export const {
  logoutUser,
  mockLogin,
  mockLoginWithPhone,
  setPermissions,
  updateForceChangePassword,
  deAuthUser,
  setModeType,
  refreshLogin,
  updateProfileImage,
} = slice.actions;
export const isAuthenticated = (state: RootState) => state.auth.token;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const isDeAuthenticated = (state: RootState) => state.auth.deAuth;
export const selectInitialModeType = (state: RootState) => state.auth.user?.initial_mode_type;

export const currentRoleSelector = createSelector(
  [selectCurrentUser],
  (user) => user?.role?.role || user?.access
);
export const selectAllPermissions = (state: RootState) => state.auth.permissions;
export const allPermissions = createSelector([(state) => state.auth], ({ user, permissions }) => {
  const permissionMap: { [element: string]: { [actionKey: string]: IModuleAction } } = {};

  permissions?.[user?.mode_type]?.forEach((permission: IModules) => {
    if (permission.type === "item" && permission.element && permission.actions) {
      if (!permissionMap[permission.element]) {
        permissionMap[permission.element] = {};
      }
      permission.actions.forEach((action) => {
        if (permission?.element) permissionMap[permission.element][action.key] = action;
      });
    }
  });

  return permissionMap;
});

export default slice.reducer;
