import { FC } from "react";
import { useLayout } from "../core";
import { useGetVersionQuery } from "../../../app/app.api";

const Footer: FC = () => {
  const { classes } = useLayout();
  const { data } = useGetVersionQuery();
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="text-gray-900 order-2 order-md-1">
          <span className="text-muted fw-bold me-2">{new Date().getFullYear()} &copy;</span>
          <a href="#" className="text-gray-800 text-hover-primary">
            {import.meta.env.VITE_APP_NAME}
          </a>
        </div>
        {/* end::Copyright */}
        <div>
          {/* <span className="text-gray-900 order-2 order-md-1">
            v{import.meta.env.VITE_APP_VERSION} {BUILD_TIMESTAMP ? BUILD_TIMESTAMP : ""}
          </span> */}
          {/* app version from API */}
          <span className="text-gray-900 order-2 order-md-1">{data?.version || ""}</span>
        </div>
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
